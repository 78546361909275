<template>
  <div class="row flex-shrink-0 mb-2">
    <div class="col-auto mx-auto">
      <div class="ma-mini-calendar">
        <flat-pickr v-model="date" :config="config"></flat-pickr>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  data() {
    return {
      config: {
        inline: true,
        wrap: true,
        enableTime: false,
        static: true,
      },
      date: null,
    };
  },
  watch: {
    date: {
      handler(val, oldVal) {
        this.$stash.miniCalendarSelectedDate = val;

        console.log("date times", this.$stash.miniCalendarSelectedDate);
        this.$stash.fullSizedCalendarApi.changeView("timeGridDay");
        this.$stash.fullSizedCalendarApi.gotoDate(
          this.$stash.miniCalendarSelectedDate
        );
      },
    },
  },
  computed: {},
  methods: {
    dayClickMini(e) {
      // this.$stash.fullSizedCalendarApi.changeView("timeGridDay", e.start);
    },
  },
  mounted() {},
  components: {
    flatPickr,
  },
};
</script>

<style>
.flatpickr-input {
  display: none;
}

.flatpickr-calendar {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
</style>
<template>
  <div class="event-calendar vh-100 vw-100 container-fluid d-flex flex-column">
    <div class="flex-shrink-0">
      <event-nav />
    </div>

    <div class="row flex-grow-1 w-100" style="min-height: 0">
      <div
        class="col-3"
        style="height: 100%; max-height: 100%"
        v-if="showSidebar"
      >
        <div class="d-flex flex-column h-100">
          <select-user
            v-if="$can('view calendar for other users')"
            v-model="selectedUser"
            class="w-100 d-block mb-2 ms-2 pe-1"
          />
          <mini-calendar :user="selectedUser" />
          <filters :user="selectedUser" />
        </div>
      </div>
      <div class="col" style="max-height: 100%; overflow: scroll">
        <full-sized-calendar :user="selectedUser" @viewEvent="viewEvent" />
        <view-event
          v-if="$stash.viewingEvent"
          :event="$stash.viewingEvent"
          :user="selectedUser"
          @close="closeViewEventWindow"
        />
        <add-event
          v-if="$stash.showAddNewEvent"
          @close="$stash.showAddNewEvent = false"
          @eventAdded="eventAdded"
          :user-to-book-as="selectedUser"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EventNav from "./calendar-partials/Nav";
import Filters from "./calendar-partials/Filters";
import MiniCalendar from "./calendar-partials/MiniCalendar";
import FullSizedCalendar from "./calendar-partials/FullSizedCalendar";
import ViewEvent from "./calendar-partials/ViewEvent";
import AddEvent from "./calendar-partials/AddEvent";
import SelectUser from "@/views/components/users/SelectUser";

export default {
  props: [],
  data() {
    return {
      showSidebar: true,
      editEvent: false,
      selectedUser: localStorage.userSelectedForEventCalendar
        ? JSON.parse(localStorage.userSelectedForEventCalendar)
        : this.$store.getters['auth/user'],
    };
  },
  watch: {
    selectedUser(newVal) {
      localStorage.setItem(
        "userSelectedForEventCalendar",
        JSON.stringify(newVal)
      );
      const vm = this;
      setTimeout(function () {
        vm.$stash.fullSizedCalendarApi.refetchEvents();
      }, 50);
    },
  },
  methods: {
    eventAdded() {
      this.$stash.fullSizedCalendarApi.refetchEvents();
      this.$stash.fullSizedCalendarApi.changeView(
        this.$stash.fullSizedCalendarApiSelectedView
      );
    },
    viewEvent(event) {
      this.$stash.viewingEvent = null;
      this.$stash.viewingEvent = event;
    },
    closeViewEventWindow() {
      this.$stash.viewingEvent = null;
      this.$stash.fullSizedCalendarApi.refetchEvents();
    },
  },
  beforeCreate() {
    this.$stash.clientInView = null;
  },
  mounted() {
    this.$EventBus.$on("toggleEventsSidebar", () => {
      this.showSidebar = !this.showSidebar;
    });
    $("#calendarAddEventModal").modal("hide");
  },
  components: {
    SelectUser,
    EventNav,
    FullSizedCalendar,
    MiniCalendar,
    Filters,
    AddEvent,
    ViewEvent,
  },
};
</script>

<style lang="scss" scoped>
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";

body,
.event-calendar {
  background: #fff !important;
}

#router-view {
  padding: 0 !important;
}

.search-for-anything,
.add-anything-menu {
  display: none !important;
}
</style>,

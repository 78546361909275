<template>
  <div
    class="modal fade"
    role="dialog"
    id="calendarAddEventModal"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div
      class="
        modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable
      "
      role="document"
      id="calendarAddEventDialog"
    >
      <div class="modal-content bg-white" id="calendarAddEventContent">
        <div class="modal-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col my-auto">
                <h6 class="modal-title fw-bold mb-0">
                  Add an event or consultation
                  <span v-if="userToBookAs.id !== this.$store.getters['auth/user'].id">
                    on behalf of {{ userToBookAs.name }}
                  </span>
                </h6>
              </div>
              <div class="col-auto my-auto ms-auto">
                <button
                  class="btn btn-sm btn-outline-primary"
                  @click="showConsultationBooker = !showConsultationBooker"
                >
                  {{
                    showConsultationBooker ? "Book Event" : "Book Consultation"
                  }}
                </button>

                       <button type="button" class="btn-close" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close"></button>

              </div>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col"></div>
            </div>
          </div>
          <div class="container-fluid" v-if="showConsultationBooker">
            <client-selector
              :client="client"
              @selectedClient="clientSelected"
            ></client-selector>
            <book-consultation
              v-if="client"
              :user="userToBookAs"
              :client="client"
              @busy="setBusy"
              @saved="saved"
            ></book-consultation>
          </div>
          <div class="container-fluid" v-if="!showConsultationBooker">
            <div class="row mb-4">
              <div class="col">
                <input
                  type="text"
                  v-model="title"
                  placeholder="Add title and time"
                  class="form-control"
                  autofocus
                  required
                  autocomplete="noton"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col">
                <div class="btn-group-toggle">
                  <label
                    class="btn btn-sm me-2"
                    :class="
                      type == 'event' ? 'btn-outline-primary' : 'btn-light'
                    "
                  >
                    <input type="radio" v-model="type" value="event" />Event
                  </label>
                  <!--
            <label
              class="btn btn-sm me-2"
              :class="
                type == 'appointment' ? 'btn-outline-primary' : 'btn-light'
              "
            >
              <input
                type="radio"
                v-model="type"
                value="appointment"
              />Appointment
            </label>
            -->

                  <label
                    class="btn btn-sm me-2"
                    :class="
                      type == 'task' ? 'btn-outline-primary' : 'btn-light'
                    "
                  >
                    <input type="radio" v-model="type" value="task" />Task
                  </label>

                  <label
                    class="btn btn-sm me-2"
                    :class="
                      type == 'busy' ? 'btn-outline-primary' : 'btn-light'
                    "
                  >
                    <input
                      type="radio"
                      v-model="type"
                      value="busy"
                      @click="title = 'Busy'"
                    />Busy
                  </label>
                </div>
              </div>
            </div>

            <!--  -->

            <div class="row mb-3" v-if="type == 'task'">
              <div class="col">
                <div v-if="taskLists.length > 0">
                  <label for>Select a task list to add this to</label>
                  <select
                    v-model="taskListId"
                    class="form-control form-control-sm"
                    required
                  >
                    <option value disabled>Select task list</option>
                    <option :value="tl.id" v-for="tl in taskLists" :key="tl.id">
                      {{ tl.title }}
                    </option>
                  </select>
                </div>
                <p class="alert alert-primary" v-else>
                  <small>
                    You need to create a task list to add this event to.
                    <a href="/tasks">Click here to create task list.</a>
                  </small>
                </p>
              </div>
            </div>
            <!--  -->

            <div class="row mb-3" v-if="type == 'appointment'">
              <div class="col">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <i class="far fa-user text-info"></i>
                    </div>
                  </div>
                  <input
                    type="text"
                    placeholder="Search for client"
                    v-model="search"
                    class="form-control form-control-sm"
                    @input="searchClients"
                  />
                </div>

                <!--  -->
                <ul
                  class="list-group"
                  v-if="clients.length > 0"
                  style="max-height: 200px; overflow: scroll"
                >
                  <li
                    class="
                      list-group-item list-group-item-action
                      cursor-pointer
                      p-2
                    "
                    @click="selectClient(c)"
                    v-for="c in clients"
                    :key="c.id"
                  >
                    <small>{{ c.name }} / {{ c.email }}</small>
                  </li>
                </ul>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-auto">
                <flat-pickr
                  v-model="$stash.newEventSelectedDates.start"
                  class="form-control form-control-sm bg-white flat-custom-w"
                  placeholder="Select date"
                  :config="dateConfig"
                  name="date"
                ></flat-pickr>
              </div>
              <div class="col-auto">
                <vue-timepicker
                  auto-scroll
                  hide-clear-button
                  :minute-interval="15"
                  v-model="startTime"
                  class="me-2 time-picker"
                ></vue-timepicker>
              </div>
              <div class="col-auto mx-auto">
                <span class>-</span>
              </div>
              <div class="col-auto">
                <flat-pickr
                  v-model="$stash.newEventSelectedDates.end"
                  class="form-control form-control-sm bg-white flat-custom-w"
                  placeholder="Select date"
                  :config="dateConfig"
                  name="date"
                ></flat-pickr>
              </div>
              <div class="col-auto">
                <vue-timepicker
                  auto-scroll
                  hide-clear-button
                  :minute-interval="15"
                  v-model="endTime"
                  class="time-picker"
                ></vue-timepicker>
              </div>
            </div>

            <!--  -->
            <div class="row mb-3" v-show="!showAddDescription">
              <div class="col">
                <button
                  class="btn btn-light btn-xs"
                  @click="showAddDescription = true"
                >
                  <i class="far fa-text me-2"></i>
                  Add description
                </button>
              </div>
            </div>
            <div class="row mb-3" v-show="showAddDescription">
              <div class="col">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <i class="far fa-text text-info"></i>
                    </div>
                  </div>
                  <textarea
                    type="text"
                    placeholder="Add a description"
                    class="form-control form-control-sm"
                    v-model="description"
                    autofocus
                  ></textarea>
                </div>
              </div>
            </div>

            <!--  -->

            <div class="row mb-3" v-show="!showAddUrl">
              <div class="col">
                <button class="btn btn-light btn-xs" @click="showAddUrl = true">
                  <i class="far fa-link me-2"></i>
                  Add URL
                </button>
              </div>
            </div>
            <div class="row mb-3" v-show="showAddUrl">
              <div class="col">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <i class="far fa-link text-info"></i>
                    </div>
                  </div>
                  <input
                    type="text"
                    placeholder="Add a url - https://example.com"
                    class="form-control form-control-sm"
                    v-model="url"
                    autofocus
                  />
                </div>
              </div>
            </div>

            <!--  -->

            <div class="row">
              <div class="col-auto ms-auto">
                <button
                  class="btn btn-primary btn-sm"
                  @click="addEvent"
                  :disabled="!canSubmit"
                >
                  <i class="far fa-check me-2"></i>Add event
                </button>
              </div>
            </div>
            <!--  -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import BookConsultation from "../../../components/booking/BookConsultation";
import ClientSelector from "./ClientSelector";

export default {
  props: ["userToBookAs"],
  data() {
    return {
      clients: [],
      taskLists: [],
      search: "",
      //
      showAddDescription: false,
      showAddUrl: false,
      title: "",
      description: "",
      url: "",
      type: "event",
      client: null,
      taskListId: "",
      showConsultationBooker: false,
      //
      startTime: {
        HH: this.getDefaultStartHour(),
        mm: this.getDefaultStartMinutes(),
      },
      endTime: {
        HH: this.getDefaultEndHour(),
        mm: this.getDefaultEndMinutes(),
      },
      dateConfig: {
        altInput: true,
        altFormat: "J M Y",
        dateFormat: "Y-m-d",
      },

      busy: false,
    };
  },
  computed: {
    start() {
      //utc
      let local =
        moment(this.$stash.newEventSelectedDates.start).format("YYYY-MM-DD") +
        " " +
        this.startTime.HH +
        ":" +
        this.startTime.mm;
      return moment(local).utc().toISOString();
    },
    end() {
      //utc
      let local =
        moment(this.$stash.newEventSelectedDates.end).format("YYYY-MM-DD") +
        " " +
        this.endTime.HH +
        ":" +
        this.endTime.mm;
      return moment(local).utc().toISOString();
    },
    startInput() {
      return moment(this.$stash.newEventSelectedDates.start).format(
        "YYYY-MM-DD HH:mm"
      );
    },
    endInput() {
      return moment(this.$stash.newEventSelectedDates.end).format(
        "YYYY-MM-DD HH:mm"
      );
    },
    canSubmit() {
      if (this.title.length > 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    clientSelected(client) {
      this.client = client;
    },
    fetchTaskLists() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/tasks/fetch-task-lists")
        .then(({ data }) => {
          this.taskLists = data;
        });
    },
    addEvent() {
      if (!this.busy) {
        this.busy = true;
        if (moment(this.end) >= moment(this.start)) {
          this.$axios
            .post(process.env.VUE_APP_API_URL + "/events/api", {
              title: this.title,
              description: this.description,
              start: this.start,
              end: this.end,
              type: this.type,
              client_id: this.client ? this.client.id : null,
              url: this.url,
              task_list_id: this.taskListId,
              user_id: this.userToBookAs.id,
            })
            .then(({ data }) => {
              this.$EventBus.$emit("alert", data);
              this.$emit("eventAdded");
              this.$stash.showAddNewEvent = false;
              $("#calendarAddEventModal").modal("hide");
              this.busy = false;
            });
        } else {
          alert("End date cannot be before start date.");
        }
      }
    },
    searchClients() {
      if (this.search.length > 2) {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/events/search-clients", {
            search: this.search,
          })
          .then(({ data }) => {
            this.clients = data;
          });
      } else {
        this.client = null;
        this.clients = [];
      }
    },
    roundToHour(date) {
      const p = 60 * 60 * 1000; // milliseconds in an hour
      return new Date(Math.round(date.getTime() / p) * p);
    },
    getDefaultStartHour() {
      //get the next hour, unless it goes onto next day.
      return this.formatHourString(
        new Date(this.$stash.newEventSelectedDates.start).getHours()
      );
    },
    getDefaultEndHour() {
      //get the next hour, unless it goes onto next day.
      return this.formatHourString(
        new Date(this.$stash.newEventSelectedDates.end).getHours()
      );
    },
    getDefaultStartMinutes() {
      return this.formatMinuteString(
        new Date(this.$stash.newEventSelectedDates.start).getMinutes()
      );
    },
    getDefaultEndMinutes() {
      return this.formatMinuteString(
        new Date(this.$stash.newEventSelectedDates.end).getMinutes()
      );
    },
    formatHourString(input) {
      if (input > 0 && input < 24) {
        if (input < 10) {
          return "0" + input.toString();
        } else {
          return input.toString();
        }
      } else {
        return "00";
      }
    },
    formatMinuteString(input) {
      if (input < 10) {
        return "0" + input.toString();
      }
      return input.toString();
    },
    selectClient(client) {
      this.client = client;
      this.search = client.name;
      this.clients = [];
    },

    // Add events

    saved() {
      this.$emit("saved");
    },
    setBusy(busy) {
      this.busy = busy;
    },
    bookConsultation() {
      if (!this.busy) {
        this.$EventBus.$emit("addEvent:bookConsultation");
      }
      this.busy = true;
    },
    cancel() {
      this.$EventBus.$emit("addEvent:cancel");
    },
  },
  mounted() {
    // this.getDefaultStartHour();
    $("#calendarAddEventModal").modal("show");
    $("#calendarAddEventModal").on("hide.bs.modal", (e) => {
      this.$emit("closed");
    });

    // this.getDefaultEndHour();
    this.fetchTaskLists();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLLL");
    },
  },
  components: {
    flatPickr,
    VueTimepicker,
    BookConsultation,
    ClientSelector,
  },
};
</script>

<style>
/* .add-event-modal {
  -webkit-transform: translate(-0%, -50%);
  transform: translate(-0%, -50%);
  position: absolute;
  top: 50%;
  right: 1.5rem;
  background: #fff;
  width: 600px;
  height: auto;
  z-index: 10000;
} */
.vue__time-picker,
.vue__time-picker input.display-time {
  width: 4rem !important;
  color: #03006b;
  border: 0;
  border-bottom: 1px solid;
  border-radius: 0;
  height: 2em;
  font-weight: normal;
}

.vue__time-picker .dropdown {
  left: unset;
  right: 0;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay,
.vue__time-picker .active {
  background: #03006b !important;
}

.flat-custom-w {
  max-width: 7rem;
}

.flat-custom-w {
  color: #03006b;
  font-weight: bold;
  border: 0;
  border-bottom: 1px solid;
  border-radius: 0;
}

@media only screen and (max-height: 600px) {
  #calendarAddEventDialog {
    height: 100% !important;
    width: 100%;
    max-width: 100%;
    min-height: 100%;
    margin: 0;
  }

  #calendarAddEventContent {
    min-height: 100%;
  }
}

#calendarAddEventModal {
  z-index: 99999;
}
</style>

<template>
  <div>
    <div>
      <div
        class="row"
        v-for="comment in comments"
        :key="comment.id"
      >
        <div class="col">
          <div
            class="bg-white rounded shadow-sm mb-3 px-2 py-2"
            :class="
              comment.created_by === $store.getters['auth/user'].id
                ? 'cursor-pointer'
                : ''
            "
          >
            <div class="row">
              <div class="col-auto my-auto text-capitalize">
                <img
                  :src="comment.creator.profile_pic_url"
                  alt="user"
                  width="32"
                  height="32"
                  class="rounded-circle"
                />
              </div>
              <div class="col my-auto">
                <div v-html="comment.body"></div>
              </div>
            </div>

            <div class="row">
              <div class="col-auto my-auto ms-auto">
                {{ comment.created_at | formatDate }}
              </div>
              <!--
              <div
                class="col-auto my-auto"
                v-if="$store.getters['auth/user'].id === comment.created_by"
              >
                <button
                  class="btn btn-outline-danger btn-sm"
                  @click.stop="deleteComment(comment)"
                >
                  <i class="far fa-trash" />
                </button>
              </div>
              -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommentView from "@/views/components/comments/CommentView";

export default {
  components: { CommentView },
  props: ["comments", "hideProfilePic", "addAllowed"],
  data() {
    return {};
  },
  methods: {
    viewComment(comment) {
      this.$emit("viewComment", comment);
    },
    deleteComment(comment) {
      this.$confirm({
        message: `Are you sure you wish to remove this comment?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            this.$axios.delete(process.env.VUE_APP_API_URL + "/comments/" + comment.id).then(({ data }) => {
              this.$EventBus.$emit("alert", data);
              this.$emit("saved");
            });
          }
        }
      });
    }
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLL");
    },
  },
};
</script>

<style>
</style>

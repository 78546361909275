<template>
  <div>
    <form @submit.prevent="createComment">
      <textarea class="form-control mb-1" v-model="comment.body" required />
      <div class="float-end">
        <button
          class="btn btn-primary btn-sm "
          type="submit"
          :disabled="saving || comment.body.length === 0"
        >
          <i class="far fa-check-circle me-1" />Add Comment
        </button>
      </div>
    </form>
  </div>
</template>

<script>

export default {
  props: ["commentableId", "commentableType"],
  data() {
    return {
      saving: false,
      comment: {
        title: "",
        body: "",
        commentable_type: this.commentableType,
        commentable_id: this.commentableId,
        public: 1
      }
    };
  },
  methods: {
    createComment() {
      this.$axios.post(process.env.VUE_APP_API_URL + "/comments", this.comment).then(({ data }) => {
        this.$EventBus.$emit("alert", data);
        this.resetComment();
        this.$emit("saved");
      });
    },
    cancel() {
      this.resetComment();
      this.$emit("cancel");
    },
    resetComment() {
      this.comment = {
        title: "",
        body: "",
        commentable_type: this.commentableType,
        commentable_id: this.commentableId
      };
      this.saving = false;
    }
  },
  created() {}
};
</script>

<style>
</style>

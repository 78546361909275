<template>
  <div class="modal fade" role="dialog" id="calendarViewEventModal">
    <div
      class="
        modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable
      "
      role="document"
      id="calendarViewEventDialog"
    >
      <div class="modal-content bg-white" id="calendarViewEventContent">
        <div class="modal-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col my-auto">
                <h6 class="mb-0">
                  <div
                    :class="
                      'swatch me-2 swatch-' +
                      event.extendedProps.type.toLowerCase()
                    "
                  ></div>
                  <div class="d-inline ms-2">
                    {{ event.extendedProps.type }}
                  </div>
                </h6>
              </div>
              <div class="col-auto my-auto ms-auto">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal" data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div v-if="showReschedule">
              <div class="form-group">
                <label>Start Date / Time</label>
                <utc-flat-pickr
                  v-model="rescheduleStart"
                  :prevent-clear="true"
                  class="w-75 p-0 m-0 form-control-sm"
                  :req="true"
                ></utc-flat-pickr>
              </div>

              <label>End Date / Time</label>
              <utc-flat-pickr
                v-model="rescheduleEnd"
                :prevent-clear="true"
                class="w-75 p-0 m-0 form-control-sm mb-3"
                :req="true"
              ></utc-flat-pickr>
              <div
                class="custom-control custom-switch mt-4"
                v-if="event.extendedProps.type.toLowerCase() === 'consultation'"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="reschedule_notification"
                  v-model="sendRescheduleNotification"
                  :checked="sendRescheduleNotification"
                />
                <label
                  class="custom-control-label"
                  for="reschedule_notification"
                >
                  Send client notification of reschedule
                </label>
              </div>
            </div>
            <div v-else>
              <div class="row mb-2">
                <div class="col">
                  <h6 class="fw-bold">
                    {{ event.title }}
                    <button
                      v-if="
                        event.extendedProps.type.toLowerCase() ===
                        'consultation'
                      "
                      class="btn btn-xs btn-light float-end"
                      @click="viewConsultation(event)"
                    >
                      <i class="far fa-eye me-1"></i>
                      View Consultation
                    </button>
                  </h6>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col">
                  <span v-if="checkDatesSameDay(event.start, event.end)">
                    {{ event.start | dateFormatAlt }} ·
                    {{ event.start | dateFormatTime }} -
                    {{ event.end | dateFormatTime }}
                  </span>
                  <span v-else-if="!event.end">
                    {{ event.start | dateFormatAlt }} ·
                    {{ event.start | dateFormatTime }} - All Day
                  </span>
                  <span v-else>
                    {{ event.start | dateFormat }} -
                    {{ event.end | dateFormat }}
                  </span>
                  <button
                    v-if="editable"
                    class="btn btn-xs btn-light float-end"
                    @click="showReschedule = true"
                  >
                    <i class="far fa-clock me-1"></i>
                    Reschedule
                  </button>
                </div>
              </div>

              <div
                class="row mb-2"
                v-if="
                  event.extendedProps.client_name &&
                  event.extendedProps.client_name.length > 1
                "
              >
                <div class="col">
                  <p class="small">
                    <span class="fw-bold me-2"
                      >Contact Information:</span
                    >
                    <span v-if="event.extendedProps.client_email" class="me-2"
                      >{{ event.extendedProps.client_email }}
                    </span>
                    <span v-if="event.extendedProps.client_phone"
                      >{{ event.extendedProps.client_phone }}
                    </span>
                  </p>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col">
                  <div class="bg-light rounded p-2">
                    <p class="">
                      <small>{{
                        event.extendedProps.description
                          ? event.extendedProps.description
                          : "No event description..."
                      }}</small>
                    </p>
                  </div>
                </div>
              </div>

              <div v-if="event.extendedProps.linked_url" class="mb-2">
                <div class="row fw-bold">
                  <div class="col">
                    External link:
                    <br />
                    <a target="_blank" :href="event.extendedProps.linked_url">
                      {{ event.extendedProps.linked_url }}
                    </a>
                  </div>
                </div>
              </div>

              <div v-if="event.extendedProps.task" class="mb-2">
                <div class="row fw-bold">
                  <div class="col">
                    Linked task: <a href="/tasks" class="">View Task</a>
                  </div>
                </div>
              </div>

              <div
                v-if="
                  event.extendedProps.client_name &&
                  event.extendedProps.client_name.length > 1
                "
              >
                <div class="row">
                  <div class="col">
                    <a
                      :href="'/clients/' + event.extendedProps.client_id"
                      class="fw-bold"
                    >
                      Linked with: {{ event.extendedProps.client_name }}
                    </a>
                    <button
                      class="btn btn-xs btn-light float-end"
                      @click="viewClient(event.extendedProps.client_id)"
                    >
                      <i class="far fa-eye me-1"></i>
                      View Client Profile
                    </button>
                  </div>
                </div>
              </div>

              <commentable-comments-view
                :commentable-id="event.id"
                :commentable-type="'Event'"
                :add-allowed="true"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-start">
          <div v-if="showReschedule" class="justify-content-end w-100">
            <button
              class="btn btn-sm btn-light"
              data-bs-dismiss="modal" data-dismiss="modal"
              aria-label="Close"
            >
              Cancel
            </button>
            <button
              class="btn btn-sm btn-outline-primary float-end"
              @click="saveReschedule(event)"
              :disabled="saving"
            >
              <i class="far fa-save me-1"></i>
              Save New Dates
            </button>
          </div>
          <div v-else class="w-100 d-flex">
            <div class="form-check w-auto" v-if="event.extendedProps.owner">
              <input
                id="allowDoubleBookingChbId"
                type="checkbox"
                class="form-check-input"
                v-model="allowDoubleBooking"
                @change="updateDoubleBooking(event)"
              />
              <label
                class="form-check-label"
                for="allowDoubleBookingChbId"
              >
                Allow double booking
              </label>
            </div>
            <button
              v-if="
                editable &&
                event.extendedProps.type.toLowerCase() !== 'consultation'
              "
              type="submit"
              class="btn btn-outline-danger btn-sm ms-auto"
              @click="deleteEvent"
            >
              Delete Event
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UtcFlatPickr from "@/views/globals/UtcFlatPickr";
import AddComment from "../../../components/comments/AddComment";
import CommentableCommentsView from "../../../components/comments/CommentableCommentsView";
export default {
  components: { CommentableCommentsView, AddComment, UtcFlatPickr },
  props: ["event", "user"],
  data() {
    return {
      allowDoubleBooking: this.event.extendedProps.allow_double_booking,
      showReschedule: false,
      rescheduleStart: this.event.start,
      rescheduleEnd: this.event.end,
      sendRescheduleNotification: true,
      saving: false,
    };
  },
  filters: {
    dateFormat(date) {
      return moment(date).format("LLL");
    },
    dateFormatAlt(date) {
      return moment(date).format("dddd, Do MMM YYYY");
    },
    dateFormatTime(date) {
      return moment(date).format("HH:mm");
    },
  },
  methods: {
    deleteEvent() {
      var confirmed = confirm(
        "Are you sure you wish to delete this event, this action cannot be undone?"
      );
      if (confirmed) {
        this.$axios
          .delete(process.env.VUE_APP_API_URL + "/events/api/" + this.event.id)
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.$EventBus.$emit("eventAdded");
            $("#calendarViewEventModal").modal("hide");
          });
      }
    },
    updateDoubleBooking(event) {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/events/" +
            event.id +
            "/update-double-booking",
          {
            allow_double_booking: this.allowDoubleBooking,
          }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("eventAdded");
          this.$EventBus.$emit("alert", data);
        });
    },
    saveReschedule(event) {
      this.saving = true;
      const sendNotification =
        event.extendedProps.type.toLowerCase() === "consultation"
          ? this.sendRescheduleNotification
          : false;
      this.$axios
        .post(
          process.env.VUE_APP_API_URL + "/events/" + event.id + "/update-dates",
          {
            start: this.rescheduleStart,
            end: this.rescheduleEnd,
            send_notification: sendNotification,
          }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.saving = false;
          $("#calendarViewEventModal").modal("hide");
        });
    },
    checkDatesSameDay(start, end) {
      return moment(start).format("DD-MM") === moment(end).format("DD-MM");
    },
    viewConsultation(event) {
      $("#calendarViewEventModal").modal("hide");
      this.$router.push(
        "/consultations/" + event.extendedProps.consultation_id
      );
    },
    viewClient(id) {
      $("#calendarViewEventModal").modal("hide");
      this.$router.push("/clients/" + id);
    },
  },
  computed: {
    editable: function () {
      return (
        this.event.extendedProps.type.toLowerCase() !== "industry" &&
        this.event.extendedProps.type.toLowerCase() !== "webinar"
      );
    },
    initialDurationMins() {
      if (this.event) {
        const start = moment(this.event.start);
        const end = moment(this.event.end);
        return moment.duration(end.diff(start)).asMinutes();
      }
      return 30;
    },
  },
  watch: {
    rescheduleStart(val) {
      let end = moment(val);
      end.add(this.initialDurationMins, "minutes");
      this.rescheduleEnd = end.format("YYYY-MM-DD HH:mm");
    },
  },
  mounted() {
    $("#calendarViewEventModal").modal("show");
    $("#calendarViewEventModal").on("hide.bs.modal", (e) => {
      this.$emit("close");
    });

    $(window).bind("keydown", function (event) {
      if (event.code == "Escape") {
        event.preventDefault();
      }
    });
  },
};
</script>

<style>
.swatch {
  width: 15px;
  height: 15px;
  display: inline-block;
}

.swatch-busy {
  background: #f8d7da;
}

.swatch-event {
  background: #e8dd43;
}

.swatch-consultation {
  background: #e84393;
}

.swatch-appointment {
  background: #5758bb;
}

.swatch-industry {
  background: #00b2ff;
}

.swatch-google-event {
  background: #ea2027;
}

.swatch-task {
  background: #f79f1f;
}

.swatch-webinar {
  background: #008000;
}

.swatch-online-programme {
  background: #00ddba;
}
</style>

<template>
  <div>
    <comment-view
      :add-allowed="addAllowed"
      v-if="viewComment"
      :comment="viewComment"
      @close="viewComment = null"
    />
    <div v-else>
      <div class="d-flex mb-2">
        <h4 class="text-primary">
          Comments
        </h4>
      </div>
      <div>
        <p class="text-center" v-if="loading"><i class="far fa-spin fa-spinner my-3" /></p>
        <div v-else>
          <div class="card bg-light border-0 shadow-none mb-3">
            <div class="card-body p-2">
              <comments-table
                :add-allowed="addAllowed"
                :comments="comments"
                @saved="fetchComments"
                @viewComment="viewSelectedComment"
              />
              <pagination v-if="comments.length > 0"
                          :pagination="pagination"
                          @navigate="fetchComments"
                          class="mb-3"
              />

              <add-comment
                  :commentable-id="commentableId"
                  :commentable-type="commentableType"
                  @saved="fetchComments"
              />

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddComment from "@/views/components/comments/AddComment";
import Pagination from "@/views/components/tables/Pagination";
import CommentsTable from "@/views/components/comments/CommentsTable";
import CommentView from "@/views/components/comments/CommentView";

export default {
  components: { CommentView, CommentsTable, Pagination, AddComment },
  props: ["commentableId", "commentableType", "addAllowed"],
  data() {
    return {
      loading: true,
      comments: [],
      pagination: {},
      viewComment: null
    };
  },
  methods: {
    fetchComments(paginationUrl) {
      let url = paginationUrl ? paginationUrl : process.env.VUE_APP_API_URL + "/comments";
      url = this.updateQueryStringParameter(
        url,
        "commentable_type",
        this.commentableType
      );
      url = this.updateQueryStringParameter(
        url,
        "commentable_id",
        this.commentableId
      );

      this.$axios.get(url).then(({ data }) => {
        this.pagination = data;
        this.comments = data.data;
        this.loading = false;
      });
    },
    viewSelectedComment(comment) {
      this.viewComment = comment;
    },
    updateQueryStringParameter(uri, key, value) {
      var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
      var separator = uri.indexOf("?") !== -1 ? "&" : "?";
      if (uri.match(re)) {
        return uri.replace(re, "$1" + key + "=" + value + "$2");
      } else {
        return uri + separator + key + "=" + value;
      }
    },
  },
  created() {
    this.fetchComments();
  }
};
</script>

<style>
</style>

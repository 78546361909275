<template>
  <div class="mb-3">
    <nav class="navbar navbar-expand-md navbar-light bg-white shadow-sm">
      <div class="container-fluid">
        <a class="navbar-brand" :href="user ? '/dashboard' : '/'">
          <img
            src="@/assets/images/brand/navy-gold-med.png"
            alt="Swandoola"
            width="28"
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <!-- Left Side Of Navbar -->
          <ul class="navbar-nav ms-4 me-auto text-center">
            <li class="nav-item me-3 my-auto">
              <button class="btn btn-sm btn-light" @click="toggleSidebar">
                <i class="far fa-bars" />
              </button>
            </li>

            <!--  -->

            <li class="nav-item ms-3 me-3 my-auto">
              <button
                class="btn btn-sm btn-light"
                :class="isActive('timeGridDay')"
                @click="switchView('timeGridDay')"
              >
                <i class="far fa-calendar-day me-2" />Day
              </button>
            </li>

            <li class="nav-item me-3 my-auto">
              <button
                class="btn btn-sm btn-light"
                :class="isActive('timeGridWeek')"
                @click="switchView('timeGridWeek')"
              >
                <i class="far fa-calendar-week me-2" />Week
              </button>
            </li>

            <li class="nav-item me-3 my-auto">
              <button
                class="btn btn-sm btn-light"
                :class="isActive('dayGridMonth')"
                @click="switchView('dayGridMonth')"
              >
                <i class="far fa-calendar-alt me-2" />Month
              </button>
            </li>

            <li class="nav-item ms-3 me-3 my-auto">
              <button
                class="btn btn-sm btn-light"
                :class="isActive('weekends')"
                @click="toggleWeekends"
              >
                <i class="far fa-calendar-star me-2" />Weekends
              </button>
            </li>

            <li class="nav-item ms-3 me-3 my-auto">
              <button
                class="btn btn-sm"
                :class="showBook ? 'btn-primary' : 'btn-light'"
                @click="toggleBookingAvailability"
              >
                <i class="far fa-calendar-check me-2" />Booking Availability
              </button>
            </li>

            <li class="nav-item ms-3 me-3 my-auto">
              <button
                class="btn btn-sm btn-outline-primary"
                @click="openAddEvent"
              >
                <i class="far fa-plus me-2" />Add Event
              </button>
            </li>
          </ul>

          <!-- Right Side Of Navbar -->
          <ul class="navbar-nav ms-auto mt-0 d-none d-md-flex">
            <li class="nav-item me-3 my-auto">
              <h5 class="mb-0">{{ currentCalendarDate }}</h5>
            </li>

            <li class="nav-item me-3 my-auto">
              <button
                class="btn btn-sm btn-light"
                v-tooltip.bottom="'Previous'"
                @click="prev"
              >
                <i class="far fa-chevron-left" />
              </button>
            </li>

            <li class="nav-item me-3 my-auto">
              <button
                class="btn btn-sm btn-light"
                v-tooltip.bottom="'Next'"
                @click="next"
              >
                <i class="far fa-chevron-right" />
              </button>
            </li>

            <li class="nav-item my-auto ms-5">
              <button @click="exitCalendar" class="btn btn-sm btn-light">
                <i class="far fa-times me-2" />Exit
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showBook: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    currentCalendarDate() {
      return this.$stash.fullSizedCalendarApi
        ? moment(this.$stash.fullSizedCalendarApi.getDate()).format("MMM YYYY")
        : "-";
    },
  },
  methods: {
    isActive(view) {
      //for weekends button
      if (view === "weekends") {
        return this.$stash.showWeekends === true ? "active" : "";
      }
      //rest of the views
      return localStorage.fullSizedCalendarApiView === view ? "active" : "";
    },
    exitCalendar() {
      $(".popover.show").popover("hide");
      $("#calendarAddEventModal").modal("hide");

      this.$stash.fullSizedCalendarApi = null;
      this.$stash.miniCalendarApi = null;
      this.$stash.selectedEventFilter = "all";
      this.$stash.selectedEvent = null;
      this.$stash.newEventSelectedDates = null;
      this.$stash.showAddNewEvent = false;
      this.$stash.calendarFilters = {};

      this.$router.push("/dashboard");
    },
    openAddEvent() {
      this.$stash.showAddNewEvent = false;
      this.$stash.newEventSelectedDates = null;
      var event = {
        start: new Date(),
        end: new Date(),
      };
      $(".popover.show").popover("hide");
      this.$nextTick(() => {
        this.$stash.newEventSelectedDates = event;
        this.$stash.showAddNewEvent = true;
        this.$EventBus.$emit("showAddNewEvent", event);
      });
    },
    next() {
      $(".popover.show").popover("hide");
      this.$stash.showAddNewEvent = false;
      this.$stash.fullSizedCalendarApi.next();
    },
    prev() {
      $(".popover.show").popover("hide");
      this.$stash.showAddNewEvent = false;
      this.$stash.fullSizedCalendarApi.prev();
    },
    toggleWeekends() {
      console.log("toggle weekends");
      $(".popover.show").popover("hide");
      this.$stash.showAddNewEvent = false;
      this.$nextTick(() => {
        this.$stash.showWeekends = !this.$stash.showWeekends;
        this.$stash.fullSizedCalendarApi.setOption(
          "weekends",
          this.$stash.showWeekends
        );
      });
    },
    switchView(view) {
      $(".popover.show").popover("hide");
      this.$stash.showAddNewEvent = false;
      localStorage.fullSizedCalendarApiView = view;
      this.$stash.fullSizedCalendarApi.changeView(view);
    },
    toggleSidebar() {
      $(".popover.show").popover("hide");
      this.$stash.showAddNewEvent = false;
      this.$EventBus.$emit("toggleEventsSidebar");
    },
    toggleBookingAvailability() {
      this.$stash.showBookingAvailablity = !this.$stash.showBookingAvailablity;

      if (this.$stash.showBookingAvailablity) {
        this.$stash.selectedEventFilter = "available-slots";
      } else {
        this.$stash.selectedEventFilter = "all";
      }
      this.showBook = this.$stash.showBookingAvailablity;
      this.$stash.fullSizedCalendarApi.refetchEvents();
    },
    logout() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/logout")
        .then(({ data }) => {
          window.location.href = "";
        });
    },
  },
  filters: {
    acronym(name) {
      var str = name;
      var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
      var acronym = matches.join(""); // JSON

      return acronym;
    },
  },
  mounted() {
    this.$stash.showBookingAvailablity = false;
    this.showBook = false;
    if (!localStorage.fullSizedCalendarApiView) {
      localStorage.fullSizedCalendarApiView =
        this.$stash.fullSizedCalendarApiDefaultView;
    }
  },
  components: {},
};
</script>

<style lang="scss">
.navbar-light .navbar-nav .nav-link {
  color: #03006b;
}
</style>

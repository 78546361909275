<template>
  <div>
    <busy visible="loading" />
    <full-calendar
      class="full-sized-calendar"
      ref="fullCalendar"
      :height="'auto'"
      min-time="06:00:00"
      max-time="22:00:00"
      last-hour="9"
      time-zone="local"
      :header="headerOptions"
      :plugins="calendarPlugins"
      :defaultView="defaultView"
      :firstDay="1"
      :weekends="$stash.showWeekends"
      :events="events"
      :business-hours="this.showBusinessHours ? businessHours : false"
      @select="handleSelect"
      @eventClick="selectThisEvent"
      @eventDrop="updateEventDates"
      @eventResize="updateEventDates"
      @dayClick="dayClick"
      :allDayText="'All Day'"
      :selectable="true"
      :event-time-format="eventTimeFormat"
    />
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
require("@fullcalendar/core/locales/en-gb.js");

export default {
  props: ["user"],
  data() {
    return {
      calendarPlugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      calendarWeekends: false,
      calendarEvents: [],
      headerOptions: false,
      selectedDates: null,
      editEvent: false,
      eventTimeFormat: {
        // like '14:30:00'
        hour: "2-digit",
        minute: "2-digit",
        meridiem: false,
        hour12: false,
      },
      showBusinessHours: true,

      loading: false,
    };
  },
  methods: {
    events: function (info, successCallback, failureCallback) {
      this.loading = true;
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/events/fetch-calendar/" +
            this.$stash.selectedEventFilter,
          {
            params: {
              user_id: this.user.id,
              start: info.start,
              end: info.end,
              filters: this.$stash.calendarFilters,
            },
          }
        )
        .then(({ data }) => {
          if (data.err) {
            failureCallback(data.err);
          } else {
            successCallback(
              Array.prototype.slice.call(data).map((event) => {
                event.start = moment.utc(event.start).format();
                event.end = moment.utc(event.end).format();
                return event;
              })
            );
          }
          this.loading = false;
        });
    },
    dayClick() {
      $(".popover.show").popover("hide");
    },
    closePopovers() {
      $(".popover.show").popover("hide");
    },
    selectThisEvent(info) {
      this.$emit("viewEvent", info.event);
    },
    updateEventDates(data) {
      var confirmed = true;
      console.log(data.event.extendedProps);
      if (data.event.extendedProps.type.toLowerCase() === "consultation") {
        confirmed = confirm(
          "Are you sure you want to re-schedule this consultation? Your client will be automatically notified of the change of date."
        );
      }

      if (confirmed && data.event.extendedProps.type != "Webinar") {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/events/" +
              data.event.id +
              "/update-dates",
            {
              start: moment(data.event.start).utc().format(),
              end: data.event.end
                ? moment(data.event.end).utc().format()
                : null,
            }
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
          });
      } else {
        data.revert();
      }
    },
    gotoPast() {
      let calendarApi = this.$refs.fullCalendar.getApi(); // from the ref="..."
      calendarApi.gotoDate("2000-01-01"); // call a method on the Calendar object
    },
    handleSelect(arg) {
      this.$stash.showAddNewEvent = false;
      this.$stash.newEventSelectedDates = null;

      $(".popover.show").popover("hide");
      var startDate = new Date(arg.start);
      var endDate = new Date(arg.end);

      const event = {
        start: startDate,
        end: endDate,
      };

      console.log(arg);
      // console.log(event);

      this.$nextTick(() => {
        this.$stash.newEventSelectedDates = event;
        this.$stash.showAddNewEvent = true;
        this.$EventBus.$emit("showAddNewEvent", event);
      });
    },
  },
  computed: {
    defaultView() {
      if (!localStorage.fullSizedCalendarApiView) {
        localStorage.fullSizedCalendarApiView =
          this.$stash.fullSizedCalendarApiDefaultView;
      }
      return localStorage.fullSizedCalendarApiView;
    },
    businessHours() {
      var hours = this.user.business_hours;
      if (hours) {
        const retArray = [];

        const dayMap = [
          { day: "monday", index: 1 },
          { day: "tuesday", index: 2 },
          { day: "wednesday", index: 3 },
          { day: "thursday", index: 4 },
          { day: "friday", index: 5 },
          { day: "saturday", index: 6 },
          { day: "sunday", index: 7 },
        ];

        dayMap.forEach((day) => {
          hours[day.day]
            .filter((slot) => slot.isOpen)
            .forEach((item) => {
              if (item.open && item.close) {
                retArray.push({
                  daysOfWeek: [day.index],
                  startTime: item.open.substr(0, 2) + ":" + item.open.substr(2),
                  endTime: item.close.substr(0, 2) + ":" + item.close.substr(2),
                });
              }
            });
        });

        return retArray;
      }

      return false;
    },
  },
  mounted() {
    this.$stash.fullSizedCalendarApi = this.$refs.fullCalendar.getApi();
    this.$stash.fullSizedCalendarApi.setOption("locale", "en-gb");

    var vm = this;
    $(window).bind("keydown", function (event) {
      if (event.code == "Escape") {
        event.preventDefault();
      }
    });

    this.$EventBus.$on("eventAdded", function () {
      let calendarApi = vm.$refs.fullCalendar.getApi();
      calendarApi.refetchEvents();
    });

    this.$EventBus.$on("calendarSetBusinessHours", function (val) {
      console.log(val);
      vm.showBusinessHours = val;
    });

    $(document).on("click", ".close-popover", function () {
      $(".popover.show").popover("hide");
    });
  },
  components: {
    FullCalendar,
  },
};
</script>

<style >
.popover {
  min-width: 450px;
  max-width: 500px;
  border: 0;
}

.popover-header {
  background: #fff;
}

.popover .swatch {
  width: 15px;
  height: 15px;
  display: inline-block;
}

.popover .swatch-busy {
  background: #f8d7da;
}

.popover .swatch-event {
  background: #e8dd43;
}

.popover .swatch-consultation {
  background: #e84393;
}

.popover .swatch-appointment {
  background: #5758bb;
}

.popover .swatch-industry {
  background: #00b2ff;
}

.popover .swatch-google-event {
  background: #ea2027;
}

.popover .swatch-task {
  background: #f79f1f;
}

.popover .swatch-webinar {
  background: #008000;
}

.popover .swatch-online-programme {
  background: #00ddba;
}

.fc-bgevent.cursor-pointer {
  opacity: 1 !important;
}
</style>

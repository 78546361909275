<template>
  <div>
    <textarea v-if="addAllowed"
              v-model="comment.body"
              class="mb-3 comment-editor"
              required />

    <div v-else v-html="comment.body"></div>

    <button
      class="btn btn-light float-end mb-3"
      @click="close"
      :disabled="saving"
    >
      <i class="far fa-times me-1" />Close
    </button>
  </div>
</template>

<script>
import debounce from "debounce";

export default {
  props: ["comment", "addAllowed"],
  data() {
    return {
      loadingNotable: true,
      commentable: null,
      saving: false
    };
  },
  computed: {
    commentBody() {
      return this.comment.body;
    }
  },
  methods: {
    fetchNotable() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/comments/" + this.comment.id + "/commentable")
        .then(({ data }) => {
          this.commentable = data;
          this.loadingNotable = false;
        });
    },
    updateComment: debounce(function() {
      this.saving = true;
      this.$axios
        .put(process.env.VUE_APP_API_URL + "/comments/" + this.comment.id, this.comment)
        .then(({ data }) => {
          this.$emit("saved");
          this.saving = false;
        });
    }, 500),

    close() {
      this.$emit("close");
    }
  },
  watch: {
    commentBody() {
      this.updateComment();
    }
  },
  created() {
    this.fetchNotable();
  }
};
</script>

<style>
.comment-editor .ProseMirror {
  min-height: 150px;
}
</style>

<template>
  <div>
    <div v-if="client" class="mb-3">
      Booking for <strong>{{ client.name }} / {{ client.email }}</strong>
    </div>
    <div v-if="!client">
      <input
        type="text"
        v-model="search"
        @input="searchForClient"
        class="form-control"
        placeholder="Search for client"
        autofocus
      />
      <ul class="list-group">
        <li
          class="list-group-item cursor-pointer"
          @click="selectClient(c)"
          v-for="c in clients"
          :key="c.id"
        >
          {{ c.name }} / {{ c.email }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["client"],
  data() {
    return {
      clients: [],
      search: "",
    };
  },
  methods: {
    selectClient(client) {
      this.$emit("selectedClient", client);
      this.clients = [];
    },
    searchForClient() {
      if (this.search.length > 2) {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/events/search-clients", {
            search: this.search,
          })
          .then(({ data }) => {
            this.clients = data;
          });
      } else {
        this.clients = [];
      }
    },
  },
};
</script>

<style>
</style>
<template>
  <div class="row flex-grow-1" style="min-height: 0">
    <div class="col" style="max-height: 100%; overflow: scroll">
      <ul class="list-group list-group-flush">
        <li class="list-group-item list-group-item-action">
          <div class="swatch me-2 swatch-busy"></div>
          <label class="custom-control-label ms-1 text-capitalize">
            Busy
          </label>
          <toggle-button v-model="calendarFilters.busy"
                         :sync="true"
                         class="float-end"
                         :color="'#03006b'" />
        </li>

        <li class="list-group-item list-group-item-action">
          <div class="swatch me-2 swatch-event"></div>
          <label class="custom-control-label ms-1 text-capitalize">
            Events
          </label>
          <toggle-button v-model="calendarFilters.event"
                         :sync="true"
                         class="float-end"
                         :color="'#03006b'" />
        </li>

        <li class="list-group-item list-group-item-action">
          <div class="swatch me-2 swatch-consultation"></div>
          <label class="custom-control-label ms-1 text-capitalize">
            Consultations
          </label>
          <toggle-button v-model="calendarFilters.consultation"
                         :sync="true"
                         class="float-end"
                         :color="'#03006b'" />
        </li>
        <li class="list-group-item list-group-item-action">
          <div class="swatch me-2 swatch-industry"></div>
          <label class="custom-control-label ms-1 text-capitalize">
            Industry Events
          </label>
          <toggle-button v-model="calendarFilters.industry"
                         :sync="true"
                         class="float-end"
                         :color="'#03006b'" />
        </li>
        <li class="list-group-item list-group-item-action">
          <div class="swatch me-2 swatch-appointment"></div>
          <label class="custom-control-label ms-1 text-capitalize">
            Client Appointments
          </label>
          <toggle-button v-model="calendarFilters.client"
                         :sync="true"
                         class="float-end"
                         :color="'#03006b'" />
        </li>
        <li class="list-group-item list-group-item-action">
          <div class="swatch me-2 swatch-task"></div>
          <label class="custom-control-label ms-1 text-capitalize">
            Tasks
          </label>
          <toggle-button v-model="calendarFilters.task"
                         :sync="true"
                         class="float-end"
                         :color="'#03006b'" />
        </li>
        <li class="list-group-item list-group-item-action">
          <div class="swatch me-2 swatch-webinar"></div>
          <label class="custom-control-label ms-1 text-capitalize">
            Webinars
          </label>
          <toggle-button v-model="calendarFilters.webinar"
                         :sync="true"
                         class="float-end"
                         :color="'#03006b'" />
        </li>
        <li class="list-group-item list-group-item-action">
          <div class="swatch me-2 swatch-google-event"></div>
          <label class="custom-control-label ms-1 text-capitalize">
            Imported Google Events
          </label>
          <toggle-button v-model="calendarFilters.google_event"
                         :sync="true"
                         class="float-end"
                         :color="'#03006b'" />
        </li>
        <li class="list-group-item list-group-item-action">
          <label class="custom-control-label ms-1 text-capitalize">
            Online Programmes
          </label>
          <toggle-button v-model="calendarFilters.online_programme"
                         :sync="true"
                         class="float-end"
                         :color="'#03006b'" />
        </li>
        <li class="list-group-item list-group-item-action">
          <div class="swatch me-2 swatch-business-hours"></div>
          <label class="custom-control-label ms-1 text-capitalize">
            Business Hours
          </label>
          <toggle-button v-model="showBusinessHours"
                         :sync="true"
                         class="float-end"
                         :color="'#03006b'" />
        </li>

        <li
          class="list-group-item list-group-item-action"
          ref="clearSupplierFiltersButton"
          @click="clearFilters"
        >
          <i class="far fa-times me-2"></i>Clear Filters
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user"],
  data() {
    return {
      calendarFilters: {},
      showBusinessHours: true,
    };
  },
  methods: {
    getDefaultFilters() {
      return {
        busy: true,
        event: true,
        consultation: true,
        industry: (localStorage.getItem("calendar_show_industry_events") === 'true'),
        client: true,
        task: true,
        webinar: true,
        google_event: true,
        online_programme: true,
      };
    },
    clearFilters() {
      this.calendarFilters = this.getDefaultFilters();
    },
  },
  watch: {
    showBusinessHours() {
      this.$EventBus.$emit("calendarSetBusinessHours", this.showBusinessHours)
    },
    calendarFilters: {
      handler: function (val) {
        localStorage.setItem("calendar_show_industry_events", val.industry);
        this.$stash.calendarFilters = val;
        this.$stash.fullSizedCalendarApi.refetchEvents();
      },
      deep: true,
    },
  },
  mounted() {
    this.clearFilters();
  },
};
</script>

<style scoped>
label {
  margin-bottom: 0;
}
</style>
<style>
.swatch {
  width: 15px;
  height: 15px;
  display: inline-block;
  position: relative;
  top: 3px;
  margin-right: 2px;
}

.swatch-busy {
  background: #f8d7da;
  opacity: 0.7;
}

.swatch-event {
  background: #e8dd43;
}

.swatch-consultation {
  background: #e84393;
}

.swatch-appointment {
  background: #5758bb;
}

.swatch-industry {
  background: #00b2ff;
}

.swatch-google-event {
  background: #ea2027;
}

.swatch-task {
  background: #f79f1f;
}

.swatch-webinar {
  background: #008000;
}

.swatch-online-programme {
  background: #00ddba;
}

.swatch-business-hours {
  background: #cccccc;
}
</style>
